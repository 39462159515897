/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable implicit-arrow-linebreak */
import axios from "axios";

let isRefreshing = false;
let failedQueue = [];

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_POPLBE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const axiosParagonInstance = axios.create({
  baseURL: `https://api.useparagon.com/projects/${process.env.REACT_APP_PARAGON_PROJECT_ID}/sdk/proxy/microsoftTeams`,
  headers: {
    "Content-Type": "application/json",
    "X-Paragon-Use-Raw-Response": "1",
    "x-paragon-queue-enabled": false,
  },
});

async function reqInterceptors(config) {
  const token = localStorage.getItem("@qrToken");
  config.headers.Authorization = token;
  return config;
}

const resInterceptors = (error, instance) => {
  const originalRequest = error.config;
  if (error?.response?.status === 401) {
    if (isRefreshing) {
      return new Promise((resolve, reject) => { // filling queue with unseccessfully requests
        failedQueue.push({ resolve, reject });
      }).then((token) => {
        originalRequest.headers.Authorization = token;
        return instance(originalRequest);
      }).catch((err) => Promise.reject(err));
    }
    isRefreshing = true;
    // return new Promise((resolve, reject) => {
    //   firebase.auth().onAuthStateChanged(async (user) => {
    //     if (user) {
    //       firebase.auth().currentUser.getIdToken(true).then((token) => {
    //         localStorage.setItem("@qrToken", token);
    //         /// TEMPORARY CODE FOR TRANSITION FROM OLD SIGN METHOD TO SDK
    //         const decodedToken = jwt_decode(token);
    //         if (decodedToken?.firebase?.sign_in_provider === "anonymous") {
    //           console.log("LOGOUT");
    //           processQueue(true, null); // returning error in unseccessfully requests queue
    //           localStorage.removeItem("@qrToken");
    //           localStorage.removeItem("@qrRefreshToken");
    //           // return logout();
    //           return;
    //         }
    //         ///
    //         instance.defaults.headers.common.Authorization = token;
    //         processQueue(null, token);
    //         resolve(instance(originalRequest));
    //       }).catch((error) => {
    //         processQueue(error, null); // returning error in unseccessfully requests queue
    //         localStorage.removeItem("@qrToken");
    //         localStorage.removeItem("@qrRefreshToken");
    //         // logout();
    //         reject(error);
    //       });
    //     } else {
    //       localStorage.removeItem("@qrToken");
    //       localStorage.removeItem("@qrRefreshToken");
    //       // logout();
    //     }
    //   });
    //   isRefreshing = false;
    // });
  }
  if (error?.response?.status > 500) {
    alert("Server Error. Please reload the dashboard and try again. If problem persists, please contact support@popl.co");
  }
  throw error;
};

// MANAGING REFRESHING TOKENS
// request interceptors
axiosInstance.interceptors.request.use(reqInterceptors, (error) => Promise.reject(error));

// response interceptor
axiosInstance.interceptors.response.use((response) => response, (error) => resInterceptors(error, axiosInstance));
