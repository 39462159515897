import React, { useContext, useEffect, useState } from "react";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputLabel from "@material-ui/core/InputLabel";
import { Button, styled } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { makeStyles } from "@material-ui/core/styles";
import MainContext from "./context/main/mainContext";

const CustomButton = styled(Button)(() => ({
  color: "#fff",
  backgroundColor: "#000",
  "&:hover": {
    backgroundColor: "#2D2D2D",
  },
  "&:disabled": {
    backgroundColor: "#9a9a9a60",
  },
  padding: "6px 0px",
  width: 170,
  textTransform: "none",
  fontSize: 13,
  fontWeight: 500,
  borderRadius: 8,
  fontFamily: "Inter, sans-serif",
  transition: "0.2s !important",
}));

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const Quotes = () => {
  const mainContext = useContext(MainContext);
  const classes = useStyles();

  const {
    getPriceConfig,
    price_config,
    coupon_config,
    generatePdfQuote,
  } = mainContext;

  useEffect(() => {
    document.title = "Popl Internal Dash - Quotes";
  });

  // Get price config
  useEffect(() => {
    if (!price_config || !coupon_config) {
      getPriceConfig((err) => {
        if (err) {
          alert(err);
        }
      });
    }
  }, []);

  // Filter price config
  const [filteredPriceConfig, setFilteredPriceConfig] = useState([]);
  useEffect(() => {
    if (price_config) {
      const filteredPriceConfig = price_config.filter((config) => config.isCurrent && config.enterprisePlan !== null && config.period === "year");
      setFilteredPriceConfig(filteredPriceConfig);
    }
  }, [price_config]);

  const handleSubmit = (e) => {
    setIsLoading(true);
    e.preventDefault();

    generatePdfQuote({
      companyName,
      clientName,
      clientEmail,
      memberQuantity: numberSeats,
      sourcePriceId: priceId,
      customMemo,
      discountId,
      includeCheckout,
    }, (err) => {
      if (err) {
        alert(err);
      }
      setIsLoading(false);
    });
  };

  const [isLoading, setIsLoading] = useState(false);
  // Inputs
  const [priceId, setPriceId] = useState("");
  const [numberSeats, setNumberSeats] = useState(1);
  const [clientName, setClientName] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [customMemo, setCustomMemo] = useState("");
  const [discountId, setDiscountId] = useState("");
  const [includeCheckout, setIncludeCheckout] = useState(false);

  return (
    <div className="projectContainer">
      <h4>Customer Details</h4>

      <div className="textInputContainer">
        <TextField
          variant="outlined"
          onChange={(e) => {
            if (e.target.value.length > 300) return alert("Too many characters");
            setClientName(e.target.value);
          }}
          label={"Client name"}
          placeholder={"Elon Musk"}
          value={clientName}
          disabled={isLoading}
          InputProps={{
            style: {
              fontSize: 12, height: 50, fontWeight: 500, borderRadius: 8, color: "#000", display: "block",
            },
          }}
          InputLabelProps={{
            style: {
              fontSize: 12, height: 50, fontWeight: 500, borderRadius: 8, color: "#828282",
            },
          }}
          style={{ width: 400 }}
          rows={1}
        />
      </div>
      <div className="textInputContainer">
        <TextField
          variant="outlined"
          onChange={(e) => {
            if (e.target.value.includes(" ")) return;
            if (e.target.value.length > 300) return alert("Too many characters");
            setClientEmail(e.target.value);
          }}
          label={"Client email address"}
          placeholder={"team-owner@popl.co"}
          value={clientEmail}
          disabled={isLoading}
          InputProps={{
            style: {
              fontSize: 12, height: 50, fontWeight: 500, borderRadius: 8, color: "#000", display: "block",
            },
          }}
          InputLabelProps={{
            style: {
              fontSize: 12, height: 50, fontWeight: 500, borderRadius: 8, color: "#828282",
            },
          }}
          style={{ width: 400 }}
          rows={1}
        />
      </div>
      <div className="textInputContainer">
        <TextField
          variant="outlined"
          onChange={(e) => {
            if (e.target.value.length > 300) return alert("Too many characters");
            setCompanyName(e.target.value);
          }}
          label={"Company Name"}
          placeholder={"Popl Co"}
          value={companyName}
          disabled={isLoading}
          InputProps={{
            style: {
              fontSize: 12, height: 50, fontWeight: 500, borderRadius: 8, color: "#000", display: "block",
            },
          }}
          InputLabelProps={{
            style: {
              fontSize: 12, height: 50, fontWeight: 500, borderRadius: 8, color: "#828282",
            },
          }}
          style={{ width: 400 }}
          rows={1}
        />
      </div>

      <h4>Plan Details</h4>
      <TextField required="true" variant="outlined" value={numberSeats} type="number" label={"Number of Members"} onChange={(e) => setNumberSeats(parseInt(e.target.value, 10))} disabled={isLoading} /><br /><br />

      <FormGroup row>
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">Plan</InputLabel>
          <Select
            value={priceId}
            onChange={(e) => setPriceId(e.target.value)}
            disabled={isLoading}
            required={true}
          >
            { filteredPriceConfig && filteredPriceConfig.map((config) => (
              <MenuItem value={config.priceId}>{config.title} - {config.code} - {config.period}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </FormGroup>
      <FormGroup row>
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">Discount</InputLabel>
          <Select
            value={discountId}
            onChange={(e) => setDiscountId(e.target.value)}
          >
            <MenuItem value={""}>{"No discount"}</MenuItem>
            { coupon_config && coupon_config.map((config) => (
              <MenuItem value={config.id}>{config.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </FormGroup>
      <FormGroup row>
        <FormControlLabel
          control={
            <Switch
              checked={includeCheckout}
              onChange={(e) => setIncludeCheckout(e.target.checked)}
              name="includeCheckout"
              inputProps={{ "aria-label": "secondary checkbox" }}
              disabled={isLoading}
            />}
          label={"Include Checkout Link"}
        />
        <br />
      </FormGroup>

      <h4>Custom Memo</h4>

      <div className="textInputContainer">
        <TextField
          variant="outlined"
          onChange={(e) => {
            if (e.target.value.length > 1000) return alert("Too many characters");
            setCustomMemo(e.target.value);
          }}
          label={"Custom text to be included on the Quote -- optional"}
          value={customMemo}
          InputProps={{
            style: {
              fontSize: 12, height: 100, fontWeight: 500, borderRadius: 8, color: "#000", display: "block",
            },
          }}
          InputLabelProps={{
            style: {
              fontSize: 12, height: 100, fontWeight: 500, borderRadius: 8, color: "#828282",
            },
          }}
          style={{ width: 500 }}
          multiline
        />
      </div>
      <br />

      <CustomButton
        onClick={isLoading ? null : handleSubmit}
      >
        {isLoading ? (<CircularProgress color="secondary" size={15} />) : "Download PDF"}
      </CustomButton>

    </div>
  );
};

export default Quotes;
