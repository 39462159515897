import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import {
  CircularProgress, TextField,
} from "@material-ui/core";
import MainContext from "./context/main/mainContext";

function getModalStyle() {
  return {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    display: "block",
    "overflow-y": "auto",
    "max-height": "80%",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export default function TeamModal(props) {
  const mainContext = useContext(MainContext);
  const {
    user,
    getTeamById,
    team,
    setMemberAdmin,
    getPriceConfig,
    price_config,
    changeTeamPlan,
    addTeamOrder,
    isProd,
  } = mainContext;

  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    // Lookup Team by ID
    getTeamById(props.teamId, (err) => {
      if (!props.teamId) return;
      if (err) {
        alert(err);
      }
      setIsLoading(false);
    });
  }, [props.teamId]);

  const makeAdmin = (userId, teamId, isAdmin) => {
    setIsLoading(true);
    setMemberAdmin(userId, teamId, isAdmin, (err) => {
      if (err) {
        alert(err);
      }
      // Refresh
      // Lookup Team by ID
      getTeamById(props.teamId, (err2) => {
        if (err2) {
          alert(err2);
        }
        setIsLoading(false);
      });
    });
  };

  // Get price config
  useEffect(() => {
    if (!price_config) {
      getPriceConfig((err) => {
        if (err) {
          alert(err);
        }
      });
    }
  }, []);

  const getMemberCount = ((team) => {
    const memberCount = team?.team?.memberTeams?.length;
    const initialQuantity = team?.team?.initialQuantity;

    if (memberCount > initialQuantity) {
      return memberCount;
    }
    return initialQuantity;
  });

  const planLookup = (team) => {
    if (!team) {
      return;
    }
    const enterprisePlan = team.enterprisePlan || null;

    if (!price_config) return (enterprisePlan);
    const plan = price_config.find((config) => config.enterprisePlan == enterprisePlan && config.isCurrent === true);
    if (team.enterprisePlan == 30) {
      return "Legacy Teams";
    }
    if (!plan) return (enterprisePlan);
    if (team.enterprisePlan == 50 && team.subscriptionTier) {
      return `${plan.title} - ${team.subscriptionTier}`;
    }
    return plan.title;
  };

  // Change Plan Submit
  const changePlan = () => {
    changeTeamPlan(team.team.id, team.team.email, team.stripeCustomer, getMemberCount(team));
  };

  const [customDevicesOrdered, setCustomDevicesOrdered] = useState(0);
  const [standardDevicesOrdered, setStandardDevicesOrdered] = useState(0);

  useEffect(() => {
    if (!team?.team?.orders) return;
    const orders = team?.team?.orders;

    const customDevices = orders.reduce((prev, current) => prev + current.customDevices, 0);
    const standardDevices = orders.reduce((prev, current) => prev + current.standardDevices, 0);

    setCustomDevicesOrdered(customDevices);
    setStandardDevicesOrdered(standardDevices);
  }, [team?.team?.orders]);

  const [isAddOrderScreen, setIsAddOrderScreen] = useState(false);

  // Inputs to add order
  const [shopifyOrderId, setShopifyOrderId] = useState("");
  const [fulfillOrderId, setFulfillOrderId] = useState("");
  const [newStandardDevices, setNewStandardDevices] = useState(0);
  const [newCustomDevices, setNewCustomDevices] = useState(0);

  const addOrder = () => {
    setIsLoading(true);

    const payload = {
      teamId: team.team.id,
      shopifyOrderId,
      fulfillOrderId,
      newStandardDevices,
      newCustomDevices,
    };

    addTeamOrder(payload, (err) => {
      if (err) {
        alert(err);
      } else {
        setShopifyOrderId("");
        setFulfillOrderId("");
        setNewStandardDevices(0);
        setNewCustomDevices(0);
        setIsAddOrderScreen(false);
      }

      // Refresh
      getTeamById(props.teamId, (err2) => {
        if (err2) {
          alert(err2);
        }
        setIsLoading(false);
      });
    });
  };

  // Make links for env
  const [stripeBaseLink, setStripeBaseLink] = useState("");

  useEffect(() => {
    if (isProd) {
      setStripeBaseLink("https://dashboard.stripe.com/");
    } else {
      setStripeBaseLink("https://dashboard.stripe.com/test/");
    }
  }, [isProd]);

  const body = (
    <div>
      <h2 id="simple-modal-title">Team ID: #{team?.team?.id}</h2>
      <p id="simple-modal-description">
        Owner Email: {team?.team?.email}
      </p>
      <p>
        Plan Level: {planLookup(team?.team)}
      </p>
      <p>
        Member Count: {getMemberCount(team)}
      </p>
      <p>
        Stripe ID: {team?.team?.stripeCustomer} <span><a href={`${stripeBaseLink}customers/${team?.team?.stripeCustomer}`} rel="noreferrer" target="_blank">View in Stripe</a></span>
      </p>
      { user.can_access_sales
        && <div className="buttonContainerAlt" onClick={isLoading ? null : (e) => changePlan(team?.team?.id)}>
          {isLoading ? (<CircularProgress color="secondary" size={15} />) : "Replace Plan (Make New Invoice)"}
        </div>
      }
      {team?.stripeCustomer?.subscriptions?.data?.length > 0 && <h3>Subscription Details</h3>}
      {team?.stripeCustomer?.subscriptions?.data?.map((subscription, index) => (<>
        <hr/>
        <p>
            Subscription ID: {subscription.id} <span><a href={`${stripeBaseLink}subscriptions/${subscription.id}`} rel="noreferrer" target="_blank">View in Stripe</a> </span>
        </p>
        <p>
            Status: {subscription.status}
        </p>
        <p>
            Current Period Start: {new Date(subscription.current_period_start * 1000).toLocaleDateString()}
        </p>
        <p>
            Cycle: <b>{subscription.items.data[0].price.recurring.interval}ly</b>
        </p>
      </>))}

      {/* Orders */}
      <h3>Orders</h3>
      <hr/>
      <div>
        <p>Customs Ordered: <b>{customDevicesOrdered}</b></p>
        <p>Standards Ordered: <b>{standardDevicesOrdered}</b></p>
        { user.can_access_ops
        && <div className="buttonContainerAlt" onClick={isLoading ? null : (e) => setIsAddOrderScreen(true)}>
          Add Order
        </div>
        }
      </div>

      {team?.team?.memberTeams?.length > 0 && <h3>Team Members ({team?.team?.memberTeams?.length} / {getMemberCount(team)})</h3>}
      {
        team?.team?.memberTeams.map((memberTeam, index) => (
          <>
            <hr/>
            <div>
              <p>
                        Member ID: {memberTeam?.member?.id}
              </p>
              <p>
                        Member Email: {memberTeam?.member?.email}
              </p>
              <p>
                        Is Admin: {memberTeam?.isAdmin ? "Yes" : "No"}
              </p>
              { user.can_access_admin
                ? memberTeam?.isAdmin ? (
                  <div className="buttonContainerAlt" onClick={isLoading ? null : (e) => makeAdmin(memberTeam?.member?.id, team?.team?.id, false)}>
                    {isLoading ? (<CircularProgress color="secondary" size={15} />) : "Remove Admin"}
                  </div>
                ) : (
                  <div className="buttonContainerAlt" onClick={isLoading ? null : (e) => makeAdmin(memberTeam?.member?.id, team?.team?.id, true)}>
                    {isLoading ? (<CircularProgress color="secondary" size={15} />) : "Make Admin"}
                  </div>
                ) : null}
            </div>
          </>
        ))
      }
    </div>
  );

  const addOrderBody = (
    <div>
      <h2 id="simple-modal-title">Team ID: #{team?.team?.id}</h2>
      <p id="simple-modal-description">
        Owner Email: {team?.team?.email}
      </p>
      <p>
        Plan Level: {planLookup(team?.team)}
      </p>
      <p>
        Member Count: {getMemberCount(team)}
      </p>

      {/* Orders */}
      <h3>Orders</h3>
      <hr/>
      <div>
        <p>Customs Ordered: <b>{customDevicesOrdered}</b></p>
        <p>Standards Ordered: <b>{standardDevicesOrdered}</b></p>
      </div>
      <hr/>
      <h3>Details</h3>

      <TextField required="true" variant="outlined" value={shopifyOrderId} label="Shopify Order ID" onChange={(e) => setShopifyOrderId(e.target.value)} disabled={isLoading} /><br/><br/>
      <TextField required="true" variant="outlined" value={fulfillOrderId} label="Fulfil Order ID" onChange={(e) => setFulfillOrderId(e.target.value)} disabled={isLoading} />

      <h3>Devices</h3>

      <TextField required="true" variant="outlined" value={newStandardDevices} type="number" label={"New Standard Devices"} onChange={(e) => setNewStandardDevices(parseInt(e.target.value, 10))} disabled={isLoading} /><br/><br/>
      <TextField required="true" variant="outlined" value={newCustomDevices} type="number" label={"New Custom Devices"} onChange={(e) => setNewCustomDevices(parseInt(e.target.value, 10))} disabled={isLoading} /><br/><br/>

      <div className="buttonContainerAlt" onClick={isLoading ? null : (e) => addOrder()}>
          Add Order
      </div>
      <br/>
      <br/>
      <div className="buttonContainer" onClick={isLoading ? null : (e) => setIsAddOrderScreen(false)}>
        {isLoading ? (<CircularProgress color="secondary" size={15} />) : "Back"}
      </div>

    </div>
  );

  return (
    <Modal
      open={props.open}
      onClose={props.closeModal}
    >
      <div style={modalStyle} className={classes.paper}>
        {isLoading ? <CircularProgress /> : isAddOrderScreen ? addOrderBody : body }
      </div>
    </Modal>
  );
}
