import React, { useContext } from "react";
import MainContext from "./context/main/mainContext";
import StripeSubscription from "./StripeSubscription";
import StripeInvoice from "./StripeInvoice";

const StripeHome = () => {
  const mainContext = useContext(MainContext);
  const {
    user,
    stripe_customer,
    stripe_subscription_extended,
    resetState,
  } = mainContext;

  // Page to show
  let component = <><p>🤔</p></>;

  if (stripe_customer) {
    component = <>
      <StripeSubscription />
    </>;
  }
  if (stripe_subscription_extended) {
    component = <>
      <StripeInvoice />
    </>;
  }

  return (
    <div className="projectContainer">
      {component}
    </div>
  );
};

export default StripeHome;
