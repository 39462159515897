import {
  CircularProgress, Modal, TextField, makeStyles,
} from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { axiosInstance, axiosParagonInstance } from "../../utilities/axios.config";
import mainContext from "../../context/main/mainContext";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    display: "block",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxHeight: 500,
    overflow: "auto",
  },
  label: {
    fontSize: 18,
    display: "flex",
    alignItems: "center",
    padding: "10px 0",
  },
  searchContainer: {
    display: "flex",
    paddingTop: 10,
  },
  memberSection: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#f1f2f4",
    padding: 20,
    marginTop: 20,
  },
  fieldItem: {
    margin: "5px 0",
    wordBreak: "break-all",
  },
  premisesAttr: {
    display: "flex",
    flexDirection: "column",
    padding: "10px 0 0 10px",
  },
}));

function ViewGroupModal({
  open, onClose, groupId, displayName, connection,
}) {
  const classes = useStyles();
  const [membersCount, setMembersCount] = useState(undefined);
  const { hrMappingConf } = useContext(mainContext);
  const [isSearching, setIsSearching] = useState(false);
  const [searchErrorText, setSearchErrorText] = useState("");
  const [searchText, setSearchText] = useState("");
  const [member, setMember] = useState(null);

  const getAdGroupUsersCount = async () => {
    try {
      const { data } = await axiosInstance.post("/internal/hr-sync/get_token", { uid: connection.uid });
      const members = await axiosParagonInstance.get(`/groups/${groupId}/members/?$count=true`, {
        headers: {
          Authorization: `Bearer ${data.token}`,
          ConsistencyLevel: "eventual",
        },
      });
      setMembersCount(members.data["@odata.count"]);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = async () => {
    try {
      setIsSearching(true);
      const { data } = await axiosInstance.post("/internal/hr-sync/get_token", { uid: connection.uid });
      const user = await axiosParagonInstance.get(`/users/${searchText}?$select=${hrMappingConf.msMemberKeys}`, {
        headers: {
          Authorization: `Bearer ${data.token}`,
          ConsistencyLevel: "eventual",
        },
      });
      setMember(user.data);
      setIsSearching(false);
    } catch (error) {
      console.log(error);
      setIsSearching(false);
      setSearchErrorText(error?.response?.data?.error?.message || "Unhadled error");
      setMember(null);
    }
  };

  useEffect(() => {
    if (open) {
      getAdGroupUsersCount();
    } else {
      setSearchErrorText("");
      setMember(null);
      setSearchText("");
      setMembersCount(undefined);
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <div className={classes.root}>
        <h2 id="simple-modal-title">Group Name: {displayName}</h2>
        <hr/>
        <div className={classes.label}>Members count: {membersCount === undefined ? <CircularProgress style={{ marginLeft: 10 }} size={20} /> : membersCount} </div>
        <hr/>
        <div className={classes.searchContainer}>
          <TextField
            style={{ width: 400 }}
            variant="outlined"
            label="User Principal Name"
            onChange={(e) => setSearchText(e.target.value?.trim())}
            disabled={isSearching}
            error={searchErrorText !== ""}
            helperText={searchErrorText}
          />
          <div style={{ width: 50, margin: "14px 0 0 30px" }} className="buttonContainerMini" onClick={handleSearch}>{isSearching ? <CircularProgress color="secondary" size={15} /> : "Search"}</div>
        </div>
        {member && (
          <div className={classes.memberSection}>
            {Object.entries(member).map(([key, value]) => {
              if (!hrMappingConf.msMemberKeys.includes(key)) return null;
              if (typeof value === "string") {
                return (
                  <div className={classes.fieldItem}>
                    <span style={{ color: "#905" }}>{key}</span>: <span style={{ color: "#690", marginLeft: 5 }}>{value}</span>
                  </div>
                );
              }
              if (key === "onPremisesExtensionAttributes") {
                return (
                  <>
                    <div className={classes.fieldItem}>
                      <span style={{ color: "#905" }}>{key}</span>:
                    </div>
                    <div className={classes.premisesAttr}>
                      {Object.entries(member[key]).map(([key, value]) => (
                        <div className={classes.fieldItem}>
                          <span style={{ color: "#905" }}>{key}</span>: <span style={{ color: "#690", marginLeft: 5 }}>{value || "null"}</span>
                        </div>
                      ))}
                    </div>
                  </>
                );
              }
              return (
                <div className={classes.fieldItem}>
                  <span style={{ color: "#905" }}>{key}</span>: <span style={{ color: "#690", marginLeft: 5 }}>{value?.toString() || <span style={{ color: "#808080" }}>null</span>}</span>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </Modal>
  );
}

export default ViewGroupModal;
