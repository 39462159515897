import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import {
  Button, Checkbox, CircularProgress, styled, TextField,
} from "@material-ui/core";
import MainContext from "./context/main/mainContext";

function getModalStyle() {
  return {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    display: "block",
    "overflow-y": "auto",
    "max-height": "80%",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function FirewallModal(props) {
  const mainContext = useContext(MainContext);
  const {
    setFirewallIp,
  } = mainContext;

  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  const [isLoading, setIsLoading] = useState(false);
  const [ipAddress, setIpAddress] = useState("");

  useEffect(() => {
    setIsLoading(true);

    // Get current IP
    const url = "https://api.ipify.org?format=json";
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        console.log("Current IP:", data.ip);
        setIpAddress(data.ip);
        setIsLoading(false);
      });
  }, []);

  const submitIp = () => {
    setIsLoading(true);
    const payload = {
      ipAddress,
    };
    setFirewallIp(payload, (err) => {
      if (err) {
        alert(`Error adding IP: ${err}`);
      } else {
        props.closeModal();
      }
      setIsLoading(false);
    });
  };

  const body = (
    <div>
      <h2 id="simple-modal-title">Add IP</h2>
      <p id="simple-modal-description">
        Add an IP to the firewall
      </p>
      <TextField
        id="ip"
        label="IP"
        variant="outlined"
        value={ipAddress}
        onChange={(e) => setIpAddress(e.target.value)}
      />
      <br/>
      <br/>
      <div className="buttonContainer" onClick={(e) => submitIp()}>
        Add IP
      </div>
    </div>
  );

  return (
    <Modal
      open={props.open}
      onClose={props.closeModal}
    >
      <div style={modalStyle} className={classes.paper}>
        {isLoading ? <CircularProgress /> : body }
      </div>
    </Modal>
  );
}
