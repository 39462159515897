import { CircularProgress, Modal, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    display: "block",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxHeight: 500,
    overflow: "auto",
  },
  btnsContainer: {
    display: "flex",
    marginTop: 20,
    justifyContent: "flex-end",
  },
}));

function ConfirmSync({
  confirmSync, setConfirmSync, onConfirm, isFetching,
}) {
  const classes = useStyles();

  return (
    <Modal
      open={confirmSync}
      onClose={() => setConfirmSync(false)}
    >
      <div className={classes.root}>
        <h3>Are you sure you want to sync team?</h3>
        <p>This will sync without sending any emails to admin</p>
        <div className={classes.btnsContainer}>
          <div
            className="buttonContainerMini"
            onClick={() => {
              if (isFetching) return;
              setConfirmSync(false);
            }}
            style={{
              width: 100,
              backgroundColor: "#ffffff",
              color: "#000000",
              border: "1px solid #000000",
              marginRight: 20,
            }}
          >
            Cancel
          </div>
          <div
            className="buttonContainerMini"
            onClick={() => {
              if (isFetching) return;
              onConfirm();
            }}
            style={{ width: 100 }}
          >
            {isFetching ? <CircularProgress color="secondary" size={15} /> : "Confirm"}
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ConfirmSync;
