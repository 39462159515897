import React, { useContext, useEffect, useState } from "react";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputLabel from "@material-ui/core/InputLabel";
import {
  Button, styled, Checkbox, FormLabel,
} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";

import { Elements, PaymentElement, useElements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripeAddPaymentModal from "./StripeAddPaymentModal";
import MainContext from "./context/main/mainContext";

const CustomButton = styled(Button)(() => ({
  color: "#fff",
  backgroundColor: "#000",
  "&:hover": {
    backgroundColor: "#2D2D2D",
  },
  "&:disabled": {
    backgroundColor: "#9a9a9a60",
  },
  padding: "6px 0px",
  width: 170,
  textTransform: "none",
  fontSize: 13,
  fontWeight: 500,
  borderRadius: 8,
  fontFamily: "Inter, sans-serif",
  transition: "0.2s !important",
}));

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const StripeSubscription = () => {
  document.title = "Popl Internal Dash - Edit Subscription";

  const mainContext = useContext(MainContext);
  const classes = useStyles();
  const {
    user,
    stripe_customer,
    stripe_customer_payment_methods,
    getCustomerPaymentMethods,
    stripe_subscription_preview,
    previewStripeSubscriptionCurrentPricing,
    previewStripeSubscriptionCustomPricing,
    setTeamPricing,
    resetStateSub,
    getPriceConfig,
    price_config,
    postDemoFlowStoredpriceId,
    postDemoFlowUpgrade,
    postDemoFlowStoredTeamId,
    postDemoFlowStoredTeamSize,
    isProd,
  } = mainContext;

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPreview, setIsLoadingPreview] = useState(false);
  const [isCustomPricing, setIsCustomPricing] = useState(false);

  // Inputs
  const [numberSeats, setNumberSeats] = useState(1);
  const [companyName, setCompanyName] = useState("");
  const [discountPercent, setDiscountPercent] = useState(0);
  const [chargeAutomatically, setChargeAutomatically] = useState(false);
  const [priceId, setPriceId] = useState("");
  const [offerFreeTrial, setOfferFreeTrial] = useState(false);

  // Get a string 2 weeks from now "YYYY-MM-DD"
  const today = new Date();
  today.setDate(today.getDate() + 14);
  const todayString = today.toISOString().split("T")[0];

  const [trialDate, setTrialDate] = useState(todayString);

  // Custom Only
  const [pricePerSeatYearlyDollars, setPricePerSeatYearlyDollars] = useState(0);

  // Multi Year
  const [multiYearSub, setMultiYearSub] = useState(false);
  const [multiYearSubYears, setMultiYearSubYears] = useState(1);

  const switchPricingMode = (e) => {
    setIsCustomPricing(e.target.checked);
  };

  // Get price config
  useEffect(() => {
    if (!price_config) {
      getPriceConfig((err) => {
        if (err) {
          alert(err);
        }
      });
    }
  }, []);

  // Filter price config
  const [filteredPriceConfig, setFilteredPriceConfig] = useState([]);
  useEffect(() => {
    if (price_config) {
      const filteredPriceConfig = price_config.filter((config) => config.isCurrent && config.enterprisePlan !== null);
      setFilteredPriceConfig(filteredPriceConfig);
    }
  }, [price_config]);

  const doPreview = () => {
    // Clear out global state
    resetStateSub();

    // Set loading to true
    setIsLoadingPreview(true);

    let fn = null;
    if (!isPriceMonthly && isCustomPricing) {
      fn = previewStripeSubscriptionCustomPricing;
    } else {
      fn = previewStripeSubscriptionCurrentPricing;
    }

    fn({
      number_seats: numberSeats,
      price_per_seat_yearly_dollars: pricePerSeatYearlyDollars,
      discount_factor: isPriceMonthly ? 1 : 1 - (discountPercent / 100),
      sourcePriceId: postDemoFlowStoredpriceId || priceId,
    }, (err) => {
      if (err) {
        alert(err);
      }
      setIsLoadingPreview(false);
    });
  };

  const doCreate = () => {
    // Clear out global state
    resetStateSub();

    // Set loading to true
    setIsLoading(true);

    // Convert YYYY-MM-DD to days from now
    let trialDays = Math.floor((new Date(trialDate) - new Date()) / (1000 * 60 * 60 * 24)) + 1;

    if (isPriceMonthly && offerFreeTrial) {
      trialDays = 30;
    }

    const payload = {
      // Details & Meta
      teamId: postDemoFlowStoredTeamId,
      stripeCustomerId: stripe_customer.id,
      companyName,
      isUpgrade: postDemoFlowUpgrade,
      // Plan Info
      sourcePriceId: postDemoFlowStoredpriceId || priceId,
      numberSeats: (isPriceMonthly && offerFreeTrial) ? null : numberSeats,
      // Don't charge automatically if it's a new team
      chargeAutomatically: postDemoFlowUpgrade ? chargeAutomatically : false,
      // Pricing details
      customPricing: (!isPriceMonthly && isCustomPricing),
      pricePerSeatDollars: (!isPriceMonthly && isCustomPricing) ? pricePerSeatYearlyDollars : undefined,
      discountFactor: isPriceMonthly ? 1 : 1 - (discountPercent / 100),
      // Trial Stuff
      trialDays: offerFreeTrial ? trialDays : 0,
      // MultiYear
      multiYearSub: (!isPriceMonthly && multiYearSub),
      multiYearSubYears: (!isPriceMonthly && multiYearSub) ? multiYearSubYears : 1,
    };

    // console.log(payload);
    // setIsLoading(false);

    setTeamPricing(payload, (err) => {
      if (err) {
        alert(`Error creating: ${err}`);
      }
      setIsLoading(false);
    });
  };

  const baseSubmit = () => {
    doPreview();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    baseSubmit();
  };

  const changeDiscountPercent = (e) => {
    let val = parseFloat(e.target.value, 10);

    if (val > 100) {
      val = 100;
    } else if (val < 0) {
      val = 0;
    }

    setDiscountPercent(val);
  };

  const [isPriceMonthly, setIsPriceMonthly] = useState(false);
  useEffect(() => {
    if (priceId) {
      const priceConfig = price_config.find((config) => config.priceId === priceId);
      if (priceConfig && priceConfig.period === "month") {
        setIsPriceMonthly(true);
      } else {
        setIsPriceMonthly(false);
      }
    }
  }, [priceId]);

  useEffect(() => {
    if (postDemoFlowStoredTeamSize !== 0) {
      setNumberSeats(postDemoFlowStoredTeamSize);
    }
  }, [postDemoFlowStoredTeamSize]);

  // Add payment modal

  const [openStripePaymentModal, setOpenStripePaymentModal] = useState(false);
  const doOpenStripePaymentModal = () => {
    console.log("Opening Stripe Payment Modal");
    setOpenStripePaymentModal(true);
  };

  const onCloseStripePaymentModal = () => {
    setOpenStripePaymentModal(false);
  };

  const stripeOptions = {
    mode: "setup",
    currency: "usd",
    paymentMethodTypes: ["card"],
    disableLink: true,
  };

  const [stripePromise, setStripePromise] = useState(null);
  useEffect(() => {
    // These keys are public
    loadStripe(isProd ? "pk_live_51ISRsnJqkGKmOFO6LLM2GcznWFLHS40RpqGm5N58il5MgKyT3XbfMw0r3jgbpGRcyq24rtr3YrK8Bbd4DaJTO7Pm00dmW3rNlq" : "pk_test_51ISRsnJqkGKmOFO6YXwekNU7nltG5qZLswJWgSyQnC78FFt8d5ZxveEdy9bqmVbVPMkxAjeqdc17Wy7sCxYlAPnK00eb2dGR0l").then((res) => {
      console.log("Stripe promise set");
      setStripePromise(res);
    });
  }, []);

  useEffect(() => {
    getCustomerPaymentMethods({
      stripeCustomerId: stripe_customer.id,
    }, (err) => {
      if (err) {
        alert(err);
      }
    });
  }, [stripe_customer]);

  return (
    <div class="flex-container">
      {!!stripePromise
        && <Elements stripe={stripePromise} options={stripeOptions}>
          <StripeAddPaymentModal stripe={stripePromise} stripeCustomerId={stripe_customer.id} open={openStripePaymentModal} closeModal={onCloseStripePaymentModal}></StripeAddPaymentModal>
        </Elements>
      }
      <div class="column">
        <h3>{postDemoFlowUpgrade ? "Change Team Pricing" : "Set New Team Pricing"}</h3>
        <div className="stripeCustomerContainer">
          <div>
            {/* Add the inputs here */}
            { (!postDemoFlowUpgrade || isCustomPricing || discountPercent !== 0 || multiYearSub) && <><TextField required="true" variant="outlined" id="companyName" value={companyName} label="Company Name" onChange={(e) => setCompanyName(e.target.value)} disabled={isLoading || isLoadingPreview} /><br /><br /></> }
            {postDemoFlowUpgrade && <FormGroup row>
              <FormControlLabel
                control={
                  <Switch
                    checked={chargeAutomatically}
                    onChange={(e) => setChargeAutomatically(e.target.checked)}
                    name="chargeAutomatically"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    disabled={isLoading}
                  />}
                label={"Charge Automatically"}
              />
              <br />
            </FormGroup> }

            <hr/>
            <h4>Plan Details</h4>
            <form autoComplete="off" onSubmit={handleSubmit}>
              <FormGroup row>

                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">Plan</InputLabel>
                  <Select
                    value={postDemoFlowStoredpriceId || priceId}
                    onChange={(e) => setPriceId(e.target.value)}
                    disabled={isLoading}
                    required={true}
                  >
                    { filteredPriceConfig && filteredPriceConfig.map((config) => (
                      <MenuItem value={config.priceId}>{config.title} - {config.code} - {config.period}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </FormGroup>
              {true
                && <>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={offerFreeTrial}
                          onChange={(e) => setOfferFreeTrial(e.target.checked)}
                          name="offerFreeTrial"
                          inputProps={{ "aria-label": "secondary checkbox" }}
                          disabled={isLoading}
                        />}
                      label={"Offer Free Trial"}
                    />
                    <br />
                  </FormGroup>
                  { offerFreeTrial && !isPriceMonthly
                    && <>
                      <div className="textInputContainer">
                        <FormControl>
                          <FormLabel>Trial End Date</FormLabel>
                          <TextField
                            variant="outlined"
                            required="true"
                            onChange={(e) => {
                              if (e.target.value.includes(" ")) return;
                              setTrialDate(e.target.value);
                            }}
                            value={trialDate}
                            type="date"
                          />
                        </FormControl>
                      </div>
                    </>
                  }
                  { offerFreeTrial && isPriceMonthly && <p><b>Free trials are always 1 month for monthlies</b></p> }
                </>
              }

              { stripe_customer_payment_methods && stripe_customer_payment_methods.length === 0
                ? <>
                  <CustomButton onClick={isLoading || isLoadingPreview ? null : doOpenStripePaymentModal}>
                  Add Payment Method
                  </CustomButton>
                </>
                : <p>Payment method on file</p>
              }
              <br />
              <hr/>
              <h4>Pricing Details</h4>

              { (!isPriceMonthly || (isPriceMonthly && !offerFreeTrial)) && <><TextField required="true" variant="outlined" value={numberSeats} type="number" label={"Number of Members"} onChange={(e) => setNumberSeats(parseInt(e.target.value, 10))} disabled={isLoading || isLoadingPreview} /><br /><br /></> }

              {isPriceMonthly ? <><p>Custom Pricing not available for monthlies</p></>
                : <>
                  <TextField required="true" variant="outlined" value={discountPercent} type="number" label={"Discount %"} onChange={changeDiscountPercent} disabled={isLoading || isLoadingPreview} /><br/><br/>

                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={isCustomPricing}
                          onChange={switchPricingMode}
                          name="pricingMode"
                          inputProps={{ "aria-label": "secondary checkbox" }}
                          disabled={isLoading}
                        />}
                      label={"Custom Pricing"}
                    />
                  </FormGroup>
                  <br />

                  {isCustomPricing && <div><TextField variant="outlined" required="true" value={pricePerSeatYearlyDollars} type="number" label={"Price Per Seat Per Year"} onChange={(e) => setPricePerSeatYearlyDollars(parseFloat(e.target.value, 10))} disabled={isLoading || isLoadingPreview} /><br /><br /></div>}
                </>
              }

              {isPriceMonthly ? <><p>Multi-Year subs are not available for monthlies</p></>
                : <>

                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={multiYearSub}
                          onChange={(e) => setMultiYearSub(e.target.checked)}
                          name="multiYearSub"
                          inputProps={{ "aria-label": "secondary checkbox" }}
                          disabled={isLoading}
                        />}
                      label={"Multi Year Sub"}
                    />
                    <br />
                  </FormGroup>

                  <br />
                  {multiYearSub && <div><TextField variant="outlined" required="true" value={multiYearSubYears} type="number" label={"Years"} onChange={(e) => setMultiYearSubYears(parseInt(e.target.value, 10))} disabled={isLoading || isLoadingPreview} /><br /><br /></div>}
                  <br />
                </>
              }
            </form>

            <div style={{ backgroundColor: "#1881e3" }} className="buttonContainer" onClick={isLoading || isLoadingPreview ? null : baseSubmit}>
              {isLoadingPreview ? (<CircularProgress color="secondary" size={15} />) : "Preview Pricing"}
            </div>
            <CustomButton
              onClick={isLoading || isLoadingPreview ? null : doCreate}
            >
              {isLoading ? (<CircularProgress color="secondary" size={15} />) : postDemoFlowUpgrade ? "Change Sub" : "Create Sub"}
            </CustomButton>
          </div>
        </div>
      </div>
      <div class="column">
        <h3>Pricing Preview</h3>
        {isLoadingPreview ? <CircularProgress />
          : !stripe_subscription_preview ? <p>No Preview</p> : (
            <div>
              <table>
                <tr>
                  <th>#</th>
                  <th>Max Members</th>
                  <th>Price Per Member</th>
                  <th>Flat Amount</th>
                </tr>

                {
                  stripe_subscription_preview && stripe_subscription_preview.tiers.map((tier, index) => (
                    <tr>
                      <td>#{index + 1}</td>
                      <td>{tier.up_to}</td>
                      <td>{tier.unit_amount ? `$${tier.unit_amount / 100}` : ""}</td>
                      <td>{tier.flat_amount ? `$${tier.flat_amount / 100}` : ""}</td>
                    </tr>
                  ))
                }
              </table>
              <p>Estimated Initial Invoice Value: ${stripe_subscription_preview && stripe_subscription_preview.tiers && stripe_subscription_preview.tiers[0] && stripe_subscription_preview.tiers[0].flat_amount ? stripe_subscription_preview.tiers[0].flat_amount / 100 : ""}</p>
            </div>
          )}
      </div>
    </div>
  );
};

export default StripeSubscription;
