import { CircularProgress } from "@material-ui/core";
import { useContext, useState } from "react";
import mainContext from "../../context/main/mainContext";
import ViewGroupModal from "./ViewGroupModal";

function Overview({ isFetchingGroups, selectedConnection }) {
  const context = useContext(mainContext);
  const [groupModalOpen, setGroupModalOpen] = useState({ isOpen: false, data: {} });

  const { adGroups } = context;

  const { integrationSyncConfig } = selectedConnection;

  return (
    <div>
      <h4>Main info</h4>
      <table>
        <tbody>
          <tr>
            <td>Sync schedule</td>
            <td>{integrationSyncConfig.syncSchedule}</td>
          </tr>
          <tr>
            <td>Last sync date</td>
            <td>{new Date(integrationSyncConfig.lastSync).toString()}</td>
          </tr>
          <tr>
            <td>Fields to sync</td>
            <td>{integrationSyncConfig.syncFields}</td>
          </tr>
          <tr>
            <td>Fields to overwrite</td>
            <td>{integrationSyncConfig.overwriteFields}</td>
          </tr>
          <tr>
            <td>Delete members</td>
            <td>{String(!!integrationSyncConfig.isDeleteMember)}</td>
          </tr>
          <tr>
            <td>Send invites</td>
            <td>{String(integrationSyncConfig.sendInvites)}</td>
          </tr>
        </tbody>
      </table>
      <h4>AD groups to sync</h4>
      <table>
        <thead>
          <tr>
            <th>Group name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {isFetchingGroups && <CircularProgress /> }
          {
            adGroups?.map(({ displayName, id }) => (
              <tr key={id}>
                <td>{displayName}</td>
                <td>
                  <div
                    className="buttonContainerMini"
                    onClick={() => setGroupModalOpen({ isOpen: true, data: { groupId: id, displayName } })}
                    style={{ width: 100, margin: "0 auto" }}
                  >
                    View
                  </div>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
      <ViewGroupModal
        connection={selectedConnection}
        open={groupModalOpen.isOpen}
        onClose={() => setGroupModalOpen({ isOpen: false, data: {} })}
        {...groupModalOpen.data}
      />
    </div>
  );
}

export default Overview;
