import React, { useContext, useEffect, useState } from "react";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import MainContext from "./context/main/mainContext";

const StripeInvoice = () => {
  document.title = "Popl Internal Dash - View Invoice";

  const mainContext = useContext(MainContext);
  const {
    stripe_customer,
    stripe_subscription_extended,
    stripe_invoice,
    sendInvoice,
    invoiceSent,
    markInvoiceSent,
    exitPostDemoFlow,
    linkTeamToStripeSub,
    postDemoFlowStoredEmail,
    postDemoFlowStoredTeamId,
    postDemoFlowStoredLoginToken,
    isProd,
  } = mainContext;

  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);

  const doSendInvoice = () => {
    // Set loading to true
    setIsLoading(true);

    sendInvoice({
      stripe_customer_id: stripe_customer.id,
      stripe_invoice_id: stripe_subscription_extended.invoice.id,
    }, (err) => {
      if (err) {
        alert(err);
      } else {
        markInvoiceSent();
      }
      setIsLoading(false);
    });
  };

  const openInvoicePageStripe = () => {
    const stripeInvoiceUrl = isProd ? `https://dashboard.stripe.com/invoices/${stripe_subscription_extended.invoice.id}` : `https://dashboard.stripe.com/test/invoices/${stripe_subscription_extended.invoice.id}`;
    window.open(stripeInvoiceUrl, "_blank");
  };

  // Call linkTeamToStripeSub on mount
  useEffect(() => {
    linkTeamToStripeSub((err) => {
      if (err) {
        alert(err);
      }
    });
  }, []);

  return (
    <div class="flex-container">
      <div class="column">
        <h3>{"Subscription Draft Created"}</h3>
        <div className="stripeCustomerContainer">
          <div>
            <br/>
            { stripe_subscription_extended?.subscription?.collection_method === "charge_automatically" ? (
              <p>This invoice <b>will be</b> charged automatically</p>
            ) : (
              <p>This invoice will <b>not</b> be charged automatically.</p>
            )
            }
            { stripe_subscription_extended.invoice.status === "void" ? (
              <>
                <p>There was no invoice generated for this change.</p>

                <div className="btnContainerPDFlow">
                  <div className="buttonContainerAlt" onClick={isLoading || isLoading2 || isLoading3 ? null : exitPostDemoFlow}>
                    {isLoading3 ? (<CircularProgress color="secondary" size={15} />) : "Exit"}
                  </div>

                </div>
              </>
            ) : (
              <div>

                <p>Invoice Amount Due ${stripe_subscription_extended.invoice.amount_due / 100}</p>
                {
                  invoiceSent ? (
                    <>
                      <div style={{ width: 210 }} className="buttonContainer" onClick={exitPostDemoFlow}>
                        {invoiceSent ? "Invoice sent, click here to exit" : "Click here to exit"}
                      </div>
                      { postDemoFlowStoredLoginToken ? (
                        <div style={{ width: 210 }} className="buttonContainer" onClick={() => window.open(`https://dash.popl.co/sign-in?addon=${postDemoFlowStoredLoginToken}`, "_blank")}>
                          {"Click here to login"}
                        </div>
                      ) : null }
                    </>
                  ) : postDemoFlowStoredEmail !== ""
                && postDemoFlowStoredTeamId !== 0
                    ? (
                      <div className="btnContainerPDFlow">
                        <div className="buttonContainerAlt" onClick={isLoading || isLoading2 || isLoading3 ? null : doSendInvoice}>
                          {isLoading ? (<CircularProgress color="secondary" size={15} />) : "Save and Send Invoice"}
                        </div>
                        <span>or</span>
                        <div className="buttonContainerAlt" onClick={isLoading || isLoading2 || isLoading3 ? null : openInvoicePageStripe}>
                          {isLoading3 ? (<CircularProgress color="secondary" size={15} />) : "Customize Invoice"}
                        </div>
                        <span>or</span>
                        <div className="buttonContainerAlt" onClick={isLoading || isLoading2 || isLoading3 ? null : exitPostDemoFlow}>
                          {isLoading3 ? (<CircularProgress color="secondary" size={15} />) : "Exit"}
                        </div>
                      </div>
                    )
                    : (
                      <div className="btnContainerPDFlow">
                        <div className="buttonContainer" onClick={isLoading ? null : doSendInvoice}>
                          {isLoading ? (<CircularProgress color="secondary" size={15} />) : "Send Invoice"}
                        </div>
                        <span>or</span>
                        <div className="buttonContainer" onClick={isLoading ? null : openInvoicePageStripe}>
                          {isLoading ? (<CircularProgress color="secondary" size={15} />) : "Customize Invoice"}
                        </div>
                      </div>
                    )
                }

                {
                  stripe_invoice?.invoice_pdf ? (
                    <div className="buttonContainer" onClick={() => window.open(stripe_invoice.invoice_pdf, "_blank")}>
                  Download Invoice
                    </div>
                  ) : null
                }
              </div>)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StripeInvoice;
